<template>
	<div class="aboutus">
		<site-header></site-header>
		<header-search></header-search>
		<bread-line :showLevel="1"></bread-line>
		<div class="content">
			<div class="contactus">
				<blue-title>发布意见或反馈</blue-title>
				<div class="listbox">

					<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
						<el-form-item label="反馈标题" prop="title">
							<el-input size="medium" class="title" placeholder="必填" v-model="ruleForm.title"></el-input>
						</el-form-item>

						<el-form-item label="反馈内容" prop="content">
							<el-input size="medium" class="contentdesc" type="textarea" v-model="ruleForm.content" placeholder="请输入">
							</el-input>
						</el-form-item>
						<el-form-item label="联系方式" prop="contact">
							<el-input size="medium" class="contact" v-model="ruleForm.contact" placeholder="邮箱或者手机号(请务必填写真实的联系方式)">
							</el-input>
						</el-form-item>
						<el-form-item style=";text-align:left;">
							<el-button size="medium" class="tijiao" type="primary" @click="submitForm('ruleForm')">提交</el-button>
						</el-form-item>

					</el-form>
				</div>
			</div>

		</div>

		<index-footer></index-footer>
	</div>
</template>

<script>
import SiteHeader from '@/components/website/SiteHeader.vue'
import HeaderSearch from '@/components/website/HeaderSearch.vue'
import IndexFooter from '@/components/website/IndexFooter.vue'
import BreadLine from '@/components/website/BreadLine.vue'
import BlueTitle from '@/components/website/BlueTitle.vue'
import NavMenu from '@/components/website/NavMenu.vue'
import { saveFeedback } from '@/api/website/feedback'
export default {
	title: 'PcWebsiteIndex',
	components: { SiteHeader, HeaderSearch, NavMenu, IndexFooter, BreadLine, BlueTitle },

	data() {
		var contact = (rule, value, callback) => {
			if (!value) {
				return callback(new Error('年龄不能为空'));
			}
			setTimeout(() => {
				if (!Number.isInteger(value)) {
					callback(new Error('请输入数字值'));
				} else {
					if (value < 18) {
						callback(new Error('必须年满18岁'));
					} else {
						callback();
					}
				}
			}, 1000);
		};

		return {
			rules: {
				title: [
					{ required: true, message: '请输入反馈标题', trigger: 'blur' },
					{ min: 3, max: 20, message: '长度在 3 到 20 个字', trigger: 'blur' }
				],
				content: [
					{ required: true, message: '请填写反馈内容', trigger: 'blur' },
					{ min: 30, max: 500, message: '长度在 30 到 500 个字', trigger: 'blur' }
				],
				contact: [
					{ required: true, message: '请输入联系方式', trigger: 'blur' },
					{ pattern: /^((0\d{2,3}-\d{7,8})|(1[3584]\d{9}))|([0-9A-Za-z\-_\.]+)@([0-9a-z]+\.[a-z]{2,3}(\.[a-z]{2})?)$/, message: "请输入合法手机号/电话号/email", trigger: "blur" }
				]
			},


			ruleForm: {
				title: '',
				content: '',
				contact: ''
			}

		}

	},

	computed: {
		site: {
			get() {
				return this.$store.state.site
			},
			set(v) {
				this.$store.dispatch('site', v);
			}
		}
	},



	mounted() {
		// this.getinfo()
	},

	watch: {

	},

	methods: {
		submitForm(formtitle) {
			this.$refs[formtitle].validate((valid) => {
				if (valid) {
					//提交数据
					saveFeedback(this.ruleForm).then(res => {
						this.$message.success('提交成功')
						this.ruleForm = {
							title: '',
							content: '',
							contact: ''
						}
					})
				} else {
					console.log('error submit!!');
					return false;
				}
			});
		},

		tourl(path) {
			this.$router.push(path)
		}

	},
};
</script>

<style lang="scss" scoped>
::v-deep .el-form-item__label {
	width: 64px;
	height: 22px;
	font-size: 16px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #000000;
}

.title ::v-deep .el-input__inner {
	width: 700px;
	height: 44px;
	background: #F2F2F2;
	border-radius: 2px;
}

.contentdesc ::v-deep .el-textarea__inner {
	width: 700px;
	height: 168px;
	background: #F2F2F2;
	border-radius: 2px;
}

.contact ::v-deep .el-input__inner {
	width: 700px;
	height: 44px;
	background: #F2F2F2;
	border-radius: 2px;
}






.aboutus {
	background: #F0F3F8;
}

.content {
	width: 100%;
	height: auto;

	.contactus {
		width: 1200px;
		min-height: 200px;
		background: #fff;
		margin: 0 auto;
	}

	.listbox {
		width: 1190px;
		margin-left: 10px;
		display: flex;
		flex-wrap: wrap;

	}
}

.tijiao {
	width: 170px;
	height: 40px;
	background: #00A3E4;
	border-radius: 0;
}
</style>